const Settings = () => {
    return ( 
        <div>
            <h1>Settings</h1>
            <div style={{display:'flex', align:'centre'}}>
                <button style={{display:'flex', marginLeft:'127px', }}>Change Projection</button>
                
                <button style={{display:'flex', marginLeft:'127px'}}> Connect to Glass</button>
                <button style={{display:'flex', marginLeft:'127px'}}>Privacy Settings</button>
            </div>
        </div>
     );
}
 
export default Settings;